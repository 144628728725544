<template>
  <div class="merchant-dropdown-group">
    <span :class="{required: isRequired, optional: isOptional}">{{ label }}</span>
    <Dropdown
      :name="name"
      :placeholder="placeholder"
      :input-type="type"
      :prefix="prefix"
      :options="options"
      :is-error="isError"
      :value="value"
      :disabled="disabled"
      tabindex="0"
      @input="onInput"
    />
    <div class="merchant-dropdown-group__messages">
      <span
        v-show="!isError && note"
        class="merchant-dropdown-group__messages__note"
        v-html="note"
      />
      <div
        v-show="isError"
        class="merchant-dropdown-group__messages__error"
      >
        <img
          :src="`/images/icon_danger_triangles.svg`"
          alt="warning icon"
        >
        <p v-html="errorMessage" />
      </div>
    </div>
  </div>
</template>
<script>
import Dropdown from '@/components/new-dropdown/Dropdown.vue';

export default {
  components: {Dropdown},
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    prefix: {
      type: String,
      default: "",
    },
    note: {
      type: String,
    },
    isError: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
    },
    isOptional: {
      type: Boolean,
    },
    options: {
      type: Array,
    },
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {}
  },
  methods: {
    onInput(val) {
      this.$emit('input', val)
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/style.scss";

.merchant-dropdown-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0px 0px 0px;

  span {
    font-size: 14px;
    line-height: 21px;
    color: $color-base-text;
    font-weight: 600;

    &.required {
      &::after {
        content: "*";
        color: $color-base;
        margin-left: 4px;
      }
    }

    &.optional {
      &::after {
        content: "(Opsional)";
        color: $color-gray-shade;
        margin-left: 4px;
        font-weight: 400;
      }
    }
  }
  
  &__messages {
    &__error {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 400 !important;
      color: $color-red-6 !important;
    }

    &__note {
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 400 !important;
      color: $color-gray-shade !important;
    }
  }
  
  & ::v-deep .wrapper-multiselect-options {
    input {
      outline: none !important;
      border: none !important;
      border-color: $color-gray-pensive-5;
      box-shadow: unset !important;
      
      &:focus {
        outline-color: $color-base !important;
        border: 1px solid $color-base !important;
        caret-color: $color-base !important;
      }
    }
  }

  ::v-deep .wrapper-input .input {
    margin-top: unset!important;
    font-size: 14px;
    color: $color-base-text;
  }
  
  ::v-deep .list-options {
    font-size: 14px;
    height: 9.3rem;
  }

  ::v-deep .single-select {
    padding: 8px 10px!important;
    font-size: 14px;
  }

  ::v-deep .arrow-down-icon-th {
    margin: unset!important;
  }

  ::v-deep .chevron {
    width: unset!important;
    height: unset!important;
    background: unset!important;
  }
}
</style>
