<template>
  <div
    class="store-merchant-card"
    @click="goToDetailMerchant(slug)"
  >
    <ImageLazy
      class="toko-img"
      :src="img"
      :alt="name + 'png'"
    />
    <div>
      <span> {{ name }}</span>
      <span>{{ city }}</span>
    </div>
  </div>
</template>

<script>
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: "StoreDetail",
  components : { ImageLazy },
  props: {
    img: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: "Merchant Name"
    },
    city: {
      type: String,
      default: "-"
    },
    slug: {
      type: String,
      default: ''
    }
  },
  methods: {
    goToDetailMerchant(slug) {
      this.$router.push(`/merchant/detail/${slug}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/style";

.store-merchant-card {
  background: #FFFFFF;
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px 0;
  color: $color-base-text;
  cursor: pointer;

  div:first-child {
    margin: auto;
    margin-bottom: 20px;
    min-height: 120px;
  }

  div:last-child {
    display: flex;
    flex-direction: column;

    > span {
      width: 14rem;
      @include for-size(mobile) {
        width: 15rem;
      }

      &:first-child {
        font-weight: 600;
      }

      &:last-child {
        font-size: 14px;
      }
    }
  }

  ::v-deep img {
    width: 182px;
    height: 120px;
    object-fit: contain;
  }

}
</style>