import FormMerchantRepository from '../api/formMerchant';
import '../types';

class FormMerchantUseCase {
  constructor() {
    /** @type {FormMerchantRepository} */
    this.api = new FormMerchantRepository();
  }

  /**
     * @param payload {Object}
     * @param status {string}
     * @return {Promise <{data: formMerchant[], err: Error}}
     */

  async create(payload) {
    const { data, err } = await this.api.create(payload);
    return { data, err };
  }
}

export default new FormMerchantUseCase();
