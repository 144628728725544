<template>
  <div class="merchant-section">
    <BreadCrumb class="top-left" />

    <!-- Banner Header -->
    <div class="merchant-section__banner-header do-flex">
      <div class="merchant-section__banner-header__text do-flex-col">
        <h1 class="merchant-section__banner-header__text__title">
          BisaBelanja
        </h1>
        <h2 class="merchant-section__banner-header__text__desc do-flex-col">
          <div>Belanja Cermat,</div>
          <div>Ongkir Hemat</div>
        </h2>
      </div>
      <ImageLazy
        :src="imgSrcBannerHeader"
        alt="merchant_banner_header.png"
        class="do-flex"
        @error="(event) => setErrorImage(event, 'merchant-banner')"
      />
    </div>
    <!-- Banner Header -->

    <!-- Merchant Registration -->
    <div class="merchant-section__registration do-flex">
      <div class="merchant-section__registration__content do-flex-col">
        <div class="merchant-section__registration__content__desc">
          Modal Gratis, Keuntungan Berlapis. Ayo Daftar Menjadi Toko di
          <span>BisaBelanja!</span>
        </div>
        <div class="merchant-section__registration__content__button">
          <Button
            text="Daftar Sekarang"
            img="ics_f_store.svg"
            type-class="red"
            @click="goToMerchantFormPage()"
          />
        </div>
      </div>
      <ImageLazy
        class="ill-img"
        :src="`/images/merchant/ill_merchant_registration.svg`"
        alt="merchant_registration.png"
      />
    </div>
    <!-- Merchant Registration -->

    <!-- Merchant Profit List -->
    <profit-and-registration
      :contents-list="merchantProfitList"
      :title="'Apa Saja Keuntungan Bergabung sebagai Toko di <span>BisaBelanja</span>'"
      :with-linear-gradient-background="true"
    />
    <!-- Merchant Profit List -->

    <!-- Merchant Registration Process -->
    <profit-and-registration
      :contents-list="merchantRegistrationProcessList"
      :title="'Bagaimana Proses Pendaftaran Menjadi Toko di <span>BisaBelanja</span>'"
      :ill-img="imgSrcRegistrationProcess"
    />
    <!-- Merchant Registration Process -->

    <!-- START list of Merchant that join with us -->
    <div id="stores-of-merchant">
      <stores-of-merchant />
    </div>
    <!-- END list of Merchant that join with us -->

    <!-- Banner Footer -->
    <div class="merchant-section__banner-footer do-flex">
      <div class="merchant-section__banner-footer__text do-flex-col">
        <div class="merchant-section__banner-footer__text__title do-flex-col">
          <div>Ingin Mendaftarkan Toko Anda di <span>BisaBelanja</span>?</div>
        </div>
        <div class="merchant-section__banner-footer__text__desc">
          Gratis biaya pendaftaran, syarat dan ketentuan yang mudah dan juga
          tanpa biaya potongan dari hasil penjualan produkmu. Yuk daftarkan Toko
          Anda sekarang!
        </div>
        <div class="merchant-section__banner-footer__text__button">
          <Button
            text="Daftar Sekarang"
            type-class="red"
            @click="goToMerchantFormPage()"
          />
        </div>
        <div class="merchant-section__banner-footer__text__faq">
          Informasi lebih lanjut. Cek <span @click="goToFAQPage()">FAQ</span> disini
        </div>
      </div>
      <ImageLazy
        :src="imgSrcBannerFooter"
        alt="merchant_banner_footer.png"
        @error="(event) => setErrorImage(event, 'merchant-footer')"
      />
    </div>
    <!-- Banner Footer -->
  </div>
</template>

<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import Button from '../../components/new-button/Button';
import StoresOfMerchant from './components/StoresOfMerchant.vue';
import mixinMobile from '../../misc/mixinMobile';
import mixinMetaInfo from '../../misc/mixinMetaInfo';
import ProfitAndRegistration from '@/components/new-profit-and-registration/ProfitAndRegistrationContent';
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '../../components/ImageLazy/util';

export default {
  name: 'Merchant',
  components: {
    BreadCrumb,
    Button,
    StoresOfMerchant,
    ProfitAndRegistration,
    ImageLazy,
  },
  mixins: [mixinMetaInfo, mixinMobile],
  data() {
    return {
      merchantProfitList: [
        {
          title: 'Gratis Bergabung',
          desc:
            'Gratis biaya pendaftaran atau biaya administrasi untuk bergabung menjadi bagian dari BisaBelanja.',
          img: 'ics_f_profile circle.svg',
        },
        {
          title: 'Tanpa Biaya Potongan',
          desc:
            'Tokomu tidak akan dikenakan biaya potongan tambahan dari hasil penjualan.',
          img: 'ics_f_money_alt.svg',
        },
        {
          title: 'Pemasaran Online',
          desc:
            'Produk akan dipasarkan dan dipromosikan melalui aplikasi Lion Parcel dengan mudah dan efisien.',
          img: 'ics_f_shopping bag.svg',
        },
        {
          title: 'Apapun Produknya, Jualnya di BisaBelanja',
          desc:
            'Kamu bisa menjual produk apapun di BisaBelanja. Mulai dari makanan, fashion, aksesoris, dan lainnya.',
          img: 'ics_f_cherry.svg',
        },
        {
          title: 'Terintegrasi Aplikasi',
          desc:
            'Ribuan pengguna aktif aplikasi Lion Parcel BisaBelanja di toko Anda.',
          img: 'ics_f_smartphone.svg',
        },
        {
          title: 'Persebaran Lokasi Pengiriman',
          desc:
            'Kirim Belanjaan Anda tanpa batas ke seluruh pelosok Indonesia.',
          img: 'ics_f_deliveryProcess.svg',
        },
      ],
      merchantRegistrationProcessList: [
        {
          title: 'Isi Formulir/Hubungi Kontak Bisnis',
          desc:
            "Isi <a href='/merchant/form'>formulir</a> pendaftaran dan menunggu respon dari Tim Lion Parcel selama hari kerja berlangsung.",
          number: '1',
        },
        {
          title: 'Konfirmasi Kerjasama',
          desc:
            'Kami akan menghubungi Anda untuk proses permintaan & mendiskusikan proposal kerjasama serta syarat & ketentuan untuk bergabung.',
          number: '2',
        },
        {
          title: 'Toko Siap Usaha',
          desc:
            'Anda akan menerima pelatihan untuk mengoptimasi toko & membantu meningkatkan omset di BisaBelanja',
          number: '3',
        },
        {
          title: 'Kontak Customer Service',
          desc: '+62-21-80820072',
          img: 'merchant/call_merchant.svg',
        },
      ],
    };
  },
  computed: {
    imgSrcBannerFooter() {
      return this.windowWidth <= 599
        ? '/images/merchant/bg_merchant_footer_mobile.webp'
        : '/images/merchant/bg_merchant_footer.webp';
    },
    imgSrcBannerHeader() {
      return this.windowWidth <= 599
        ? '/images/merchant/bg_merchant_header_mobile.webp'
        : '/images/merchant/bg_merchant_header.webp';
    },
    imgSrcRegistrationProcess() {
      return this.isMobileSize
        ? '/images/merchant/ill_merchant_registration_process_mobile.svg'
        : '/images/merchant/ill_merchant_registration_process.svg';
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    goToFAQPage() {
      this.$router.push('/faq');
    },
    goToMerchantFormPage() {
      this.$router.push('/merchant/form');
    },
    setErrorImage(event, addClass) {
      setImageDefault(event, addClass);
    },
  },
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
