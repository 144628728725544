<template>
  <ModalVue
    v-if="showModal"
    :use-icon-close="false"
    width="420px"
  >
    <!-- container Modal -->
    <div class="modal-content">
      <!-- icon -->
      <div class="modal-content__icon">
        <img
          :src="`/images/Ill_Badge_Success.svg`"
          alt="success-icon"
        >
      </div>
      <!-- Heading -->
      <div class="modal-content__heading">
        <span>Pendaftaran Merchant BisaBelanja Berhasil</span>
      </div>
      <!-- description -->
      <div class="modal-content__description">
        <h1>
          Kami akan memproses data Anda & menghubungi via email/nomor handphone secepatnya
        </h1>
      </div>
      <!-- button -->
      <div class="modal-content__button">
        <Button
          type-class="red"
          text="Selesai"
          @click="$router.push('/merchant')"
        />
      </div>
    </div>
  </ModalVue>
</template>

<script>
import Button from "@/components/new-button/Button.vue";
import ModalVue from '@/components/new-modal/Modal.vue';

export default {
  components: {
    ModalVue,
    Button
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';

.modal-content {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  margin: auto;
    
  @include for-size(mobile) {
    width: 85%;
  }

  &__icon {
    text-align: center;
    height: auto;
    margin: auto;
  }

  &__heading {
    padding-bottom: 8px;
    color: $color-base-text;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    font-size: 14px;
  }

  &__description {
    margin: auto;
    font-size: 12px;
    text-align: center;
    color: $color-text-small;
    line-height: 18px;
    font-weight: 400;
    padding-bottom: 24px;
  }

  &__button {
    margin: auto;
    width: 95%;
    margin-bottom: 5px;
    
    @include for-size(mobile) {
      width: 85%;
    }
  }
}
</style>
