<template>
  <section class="merchant-section">
    <BreadCrumb
      v-if="MERCHANT.merchant_name"
      class="top-left"
      :title="MERCHANT.merchant_name"
      :is-use-route-name="true"
    />

    <!-- Banner -->
    <div
      v-if="isMerchantActive && !isLoading"
      class="merchant-section__banner-header do-flex"
    >
      <ImageLazy
        class="desktop-banner"
        :src="getBannerMerchantDetail"
        alt="merchant_banner_header.png"
      />
    </div>
    <!-- Banner -->

    <!-- Merchant Header -->
    <!-- Merchant Title -->
    <div
      v-if="isMerchantActive && !isLoading"
      class="merchant-section__merchant-header"
    >
      <div class="merchant-section__merchant-header__title">
        <h1 class="merchant-section__merchant-header__title__name">
          {{ MERCHANT.merchant_name }}
        </h1>
        <h4 class="merchant-section__merchant-header__title__description">
          {{ MERCHANT.description }}
        </h4>
      </div>
      <!-- Merchant Title -->
    
      <!-- Merchant Share Social Media -->
      <SocialMediaShare :data="SocialMediaShareContentData" />
      <!-- Merchant Share Social Media -->
    <!-- Merchant Header -->
    </div>

    <!-- Product List Section -->
    <div class="merchant-section__product-list">
      <div
        v-if="isLoading"
        class="merchant-detail-loader"
      >
        <Loader
          color-class="l-grey"
          :position-left="loaderPosition"  
        />
      </div>
      <div
        v-if="isMerchantActive && isProductNotEmpty && !isLoading"
        class="merchant-section__product-list__content"
      >
        <product-card
          v-for="(product, i) of paginatedData"
          :key="i"
          :product="product"
          :is-not-active="!product.stock"
        />
      </div>
      <div
        v-if="(!isMerchantActive || !isProductNotEmpty) && !isLoading"
        class="merchant-section__product-list__merchant-not-active"
      >
        <img
          :src="`/images/merchant/merchant-not-active.svg`"
          alt="merchant-not-active"
        >
        <h1 class="merchant-section__product-list__merchant-not-active__title">
          {{ (!isMerchantActive) ? 'Toko Tidak Tersedia' : !isProductNotEmpty ? 'Toko Belum Memiliki Produk' : '' }}
        </h1>
        <span class="merchant-section__product-list__merchant-not-active__description">
          {{
            !isMerchantActive ?
              'Toko yang Anda cari tidak tersedia. Yuk, cek toko lainnya sekarang' :
              !isProductNotEmpty ?
                'Toko ini masih mengatur etalase produk, Yuk cek toko lainnya sekarang' : ''
          }}
        </span>
        <Button
          text="Lihat Toko Lainnya"
          @click="$router.push('/merchant/all')"
        />
      </div>
    </div>
    <!-- Product List Section -->

    <!-- Pagination -->
    <div
      v-if="isMerchantActive && isProductNotEmpty"
      class="merchant-section__pagination"
    >
      <pagination
        v-model="page"
        :per-page="perPage"
        :total-all-item="productsData.length"
        @next="nextPage"
        @prev="prevPage"
      />
    </div>
    <!-- Pagination -->
  </section>
</template>
<script>
import BreadCrumb from "@/components/new-breadcrumb/BreadCrumb.vue";
import mixinMobile from '@/misc/mixinMobile';
import mixinMetaInfo from '@/misc/mixinMetaInfo';
import { convertRp } from "@/misc/currency";
import ProductCard from "../components/ProductCard.vue";
import MerchantUseCase from '../app/usecase/merchant'
import Pagination from "@/components/new-pagination/Pagination.vue";
import Button from '@/components/new-button/Button.vue'
import Loader from "@/components/new-loader/Loader.vue";
import SocialMediaShare from "@/components/social-media-share/SocialMediaShare.vue";
import ImageLazy from "@/components/ImageLazy/index.vue";
import { mapGetters } from 'vuex'

export default {
  name: 'MerchantDetail',
  components: {
    BreadCrumb,
    ProductCard,
    Pagination,
    Button,Loader,
    SocialMediaShare,
    ImageLazy
  },
  mixins: [mixinMetaInfo, mixinMobile],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: `${this.MERCHANT.merchant_name} - Merchant | BisaBelanja`,
      description: `Yuk, Belanja di ${this.MERCHANT.merchant_name}, tersedia banyak pilihan produk menarik dengan kualitas yang terjamin.`,
      keywords: this.MERCHANT.merchant_name,
      publishedTime: this.MERCHANT.created_at,
      image: encodeURI(this.MERCHANT.image_banner_url),
    })
  },
  data() {
    return {
      isCopyLinkSuccess: false,
      isLoading: true,
      page: 1,
      perPage: 18,
      merchantUseCase: MerchantUseCase,
      defaultMetaInfo: '',
      productsData: [],
      convertRp,
      SocialMediaShareContentData: {
        url: new URL(!this.isSsr() ? location.href : `http://${process.env.DEV_HOST}:${process.env.APP_PORT}`).href,
        title: '',
        description: '',
        quote: '',
        hashtags: ''
      }
    }
  },
  async asyncData({ route, store }){
    try {
      const merchantSlugArray = route.params.merchant_slug.split('-') 
      const merchantId = merchantSlugArray[merchantSlugArray.length-1]
      const res = await MerchantUseCase.getMerchant(merchantId)
      store.dispatch('setMerchant', res.data)
    } catch (error) {
      store.dispatch('setMerchant', [])
    }
  },
  computed: {
    ...mapGetters(['MERCHANT']),
    getBannerMerchantDetail() {
      return this.windowWidth <= 599 ? this.MERCHANT.image_banner_url : '/images/merchant/merchant-detail-banner.svg'
    },
    paginatedData() {
      return this.productsData.slice((this.page - 1) * 18, this.page * 18 )
    },
    isMerchantNotEmpty() {
      return Object.keys(this.MERCHANT).length
    },
    isMerchantActive() {
      return this.isMerchantNotEmpty && this.MERCHANT.status === 'active'
    },
    isProductNotEmpty() {
      return this.productsData.length
    },
    loaderPosition() {
      return this.isMobileSize ? '40%' : '47%'
    }
  },
  created() {
    this.getMerchantIdFromSlug()
  },
  mounted() {
    this.getMerchant()
    this.getProducts()
  },
  methods: {
    getMerchantIdFromSlug() {
      let merchantSlugArray = this.$route.params.merchant_slug.split('-') 
      this.MERCHANT.merchantId = merchantSlugArray[merchantSlugArray.length-1]
    },
    async getMerchant() {
      try {
        const res = await this.merchantUseCase.getMerchant(this.MERCHANT.merchantId)
        this.$store.dispatch('setMerchant', res.data)
        const copyUrl = this.SocialMediaShareContentData.url
        this.SocialMediaShareContentData = {
          ...this.SocialMediaShareContentData,
          title: `Yuk, Belanja di ${res.data.merchant_name}, tersedia banyak pilihan produk menarik dengan kualitas yang terjamin. Cek selengkapnya di `,
          hashtags: 'lionparcel',
          copyUrl
        }
      } catch (error) {
        this.$store.dispatch('setMerchant', [])
      }
    },
    async getProducts() {
      try {
        const res = await this.merchantUseCase.getProducts(this.MERCHANT.merchantId)
        this.productsData = res.data
      } catch (error) {
        this.productsData = []
      } finally {
        this.isLoading = false
        window.scrollTo({top: 0, left: 0})
      }
    },
    nextPage() {
      this.page = this.page + 1
      window.scrollTo({top: 0, left: 0})
    },
    prevPage() {
      if (this.page === 1) return
      this.page = this.page - 1
      window.scrollTo({top: 0, left: 0})
    },
  }
}
</script>
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
@import "style.scss";

</style>
