<template>
  <div class="merchant-radio-group">
    <span :class="{ required: isRequired && label, optional: isOptional }">{{ label }}</span>
    <div class="coat-radio">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="wrapper-radio" 
      >
        <input
          :id="option.value"
          type="radio"
          :name="name"
          :value="option.value"
          @input="onInput"
        >
        <span>{{ option.label }}</span>
      </div>
    </div>
    <div class="merchant-radio-group__messages">
      <span
        v-show="!isError && note"
        class="merchant-radio-group__messages__note"
        v-html="note"
      />
      <div
        v-show="isError"
        class="merchant-radio-group__messages__error"
      >
        <img
          :src="`/images/icon_danger_triangles.svg`"
          alt="warning icon"
        >
        <p v-html="errorMessage" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    name: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
    },
    label: {
      type: String,
      default: ''
    },
    note: {
      type: String,
      default: ''
    },
    isError: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
      default: ''
    },
    isOptional: {
      type: Boolean,
    },
    options: {
        type: Array,
        default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {
    onInput(val) {
      this.$emit('input', val)
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/style.scss";

.merchant-radio-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0px 0px 0px;

  span {
    font-size: 14px;
    line-height: 21px;
    color: $color-base-text;
    font-weight: 600;

    &.required {
      &::after {
        content: "*";
        color: $color-base;
        margin-left: 4px;
      }
    }

    &.optional {
      &::after {
        content: "(Opsional)";
        color: $color-gray-shade;
        margin-left: 4px;
        font-weight: 400;
      }
    }
  }

  .coat-radio {
    display: flex;
  }

  .wrapper-radio {
    input[type="radio"] {
        vertical-align: middle;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        opacity: 0.5;
    }

    input[type="radio"]:checked {
        accent-color: $color-base;
        opacity: 1;
    }
        
    span {
        font-weight: 400;
        margin-right: 50px;
    }
  }

  &__icon {
    position: absolute;
    right: 10px;
    bottom: 0;
    top: 0;
    margin: auto;
  }

  &__messages {
    &__error {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 400 !important;
      color: $color-red-6 !important;
    }

    &__note {
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 400 !important;
      color: $color-gray-shade !important;
    }
  }
}
</style>
