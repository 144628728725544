<template>
  <div class="wrapper-stores">
    <div class="wrapper-stores__title">
      Toko yang Sudah Bergabung Bersama
    </div>
    <Loader
      v-if="isLoading"
      color-class="l-grey"
    />
    <div id="stores-desktop">
      <store-detail 
        v-for="toko in dataMerchant"
        :key="toko.merchant_name"
        :img="toko.image_url"
        :city="toko.city"
        :name="toko.merchant_name"
        :slug="formatSlug(toko.merchant_name, toko.merchant_id)"
      />
    </div>
    <div id="stores-mobile">
      <carousel
        :name="carouselName"
        :size="0"
        :total-all-data="dataMerchant.length"
      >
        <template #item>
          <div
            v-for="(toko, index) in dataMerchant"
            :id="`${carouselName}-${index+1}`"
            :key="`${index}-key`"
            class="slide-number"
            style="margin-right: 20px;"
          >
            <store-detail
              :key="toko.merchant_name"
              :img="toko.image_url"
              :city="toko.city"
              :name="toko.merchant_name"
              :slug="formatSlug(toko.merchant_name, toko.merchant_id)"
            />
          </div>
        </template>
      </carousel>
    </div>
    <div class="wrapper-stores__btn">
      <Button
        text="Lihat Semua Toko"
        type-class="red"
        @click="goToAllMerchant"
      />
    </div>
  </div>
</template>

<script>
import StoreDetail from "./StoreDetail";
import Carousel from "@/components/new-carousel/Carousel";
import API from '../app/usecase/merchant'
import Loader from "@/components/new-loader/Loader.vue";
import Button from "@/components/new-button/Button.vue";

export default {
  name: "StoresOfMerchant",
  components: {
    Carousel,
    StoreDetail,
    Loader,
    Button
    },
  data() {
    return {
      isLoading: false,
      carouselName: "merchant",
      dataMerchant: [],
    }
  },
  created() {
    this.fetchDataMerchant()
  },
  methods: {
    async fetchDataMerchant() {
      this.isLoading = true
      const data = await API.getAll()
      if(data.err !== null) {
        this.isLoading = false
      }else {
        let temp = []
        for(let i = 0; i < 5; i++) {
          temp.push(data.data.data[i])
        }
        this.dataMerchant = temp
        this.isLoading = false
      }
  },
    goToAllMerchant() {
      this.$router.push('/merchant/all')
    },
    formatSlug(name, id) {
      let merchant = encodeURIComponent(name.split(' ').join('-').toLowerCase())
      return`${merchant}-${id}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/style";

#stores-desktop {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include for-size(mobile) {
    display: none;
  }

  > div {
    margin-right: 20px;
    margin-bottom: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

}

#stores-mobile {
  display: none;
  @include for-size(mobile) {
    display: block;
    padding-left: 24px;
  }
}

.wrapper-stores {
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  text-align: center;

  > div {
    margin-bottom: 60px;
  }

  &__title {
    color: $color-base-text;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    padding-left: 12px;
    padding-right: 12px;
    @include for-size(mobile) {
      font-size: 23px;
    }

    &:after {
      content: "BisaBelanja";
      color: $color-base;
    }
  }

  &__more-detail {
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    padding: 0 24px;
  }
  &__btn {
  width: 178px;
  margin: auto;
  }
}

.slide-number {
  display: inline-block;
  vertical-align: middle;
}
</style>
