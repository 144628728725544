<template>
  <div>
    <transition name="bounce">
      <div
        v-if="isCopyLinkSuccess"
        class="sucess-copy-alert"
      >
        <div class="sucess-copy-alert__card">
          <div class="sucess-copy-alert__card__desc">
            <img
              src="/images/check_circle.svg"
              alt="Icon Ceklis Background Bulat Lion Parcel"
            >
            <p>Link berhasil disalin</p>
          </div>
          <div
            class="sucess-copy-alert__card__close pointer"
            @click="closeAlert"
          >
            <img
              src="/images/x.svg"
              alt="Icon Silang Lion Parcel"
            >
          </div>
        </div>
      </div>
    </transition>
    <div :class="['social-media-share ', {'column' : isColumn}]">
      <a
        :href="generateUrl('facebook', data.url, data.title, data.hashtags)"
        rel="noreferrer noopener"
        target="_blank"
      >
        <img
          src="/images/fb.png"
          class="social-media-share__social-icon"
          alt="Facebook"
          title="Facebook"
          loading="lazy"
          width="100"
          height="100"
        >
      </a>
      <a
        :href="generateUrl('twitter', data.url, data.title, data.hashtags)"
        rel="noreferrer noopener"
        target="_blank"
      >
        <img 
          src="/images/twitter.png"
          class="social-media-share__social-icon"
          alt="twitter"
          title="Twitter"
          loading="lazy"
          width="100"
          height="100" 
        >
      </a>
      <a 
        :href="generateUrl('whatsapp', data.url, data.title, data.hashtags)" 
        rel="noreferrer noopener"
        target="_blank"
      >
        <img
          src="/images/whatsapp.png"
          class="social-media-share__social-icon"
          alt="whatsapp" 
          title="Whatsapp"
          loading="lazy"
          width="100"
          height="100"
        >
      </a>
      <div
        class="pointer"
        @click="copyUrl"
      >
        <img
          src="/images/copy-shareit.png"
          class="social-media-share__social-icon"
          alt="copy link"
          title="Copy Link"
          loading="lazy"
          width="100"
          height="100"
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    isColumn: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      isCopyLinkSuccess: false,
    }
  },
  methods: {
    async copyUrl() {
      try {
        await navigator.clipboard.writeText(
          this.data.copyUrl ? this.data.copyUrl : this.data.title + this.data.url
        )
        this.isCopyLinkSuccess = true
        setTimeout(() => {
          this.isCopyLinkSuccess = false
        }, 1500);
      } catch (error) {
        this.isCopyLinkSuccess = false
        console.error('Failed to copy link: ', error)
      }
    },
    closeAlert() {
      this.isCopyLinkSuccess = false
    },
    generateUrl(network, url, title, hashtags, ) {
      switch (network) {
        case 'facebook':
          return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&description=&quote=&hashtag=${encodeURIComponent(`#${hashtags}`)}`
        case 'twitter':
          return `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}&hashtags=${encodeURI(hashtags)}`
        case 'whatsapp':
          return `https://api.whatsapp.com/send?text=${encodeURIComponent(title)}%0D%0A${encodeURIComponent(url)}%0D%0A`
        default:
          break;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/style.scss";

@mixin CustomFontSize($font-size: 34px, $line-height: 51px) {
  font-size: $font-size;
  line-height: $line-height;
}

.pointer {
  cursor: pointer;
}

.social-media-share {
  width: 100%;
  display: flex;
  flex-direction: column;

  &:not(.column) {
    flex-direction: row;
  }

  align-items: center;

  &__social-icon {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 30px;
    height: 30px;
    margin: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: center;
    align-self: center;
  }

  &__success-copy {
    color: $color-base;

    @include CustomFontSize(0.625rem, 0.875rem)
  }
}

.sucess-copy-alert {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;

  &__card {
    width: 328px;
    padding: 16px;
    border-radius: 8px;
    background-color: #00875A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    &__desc {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      p {
        margin-top: 0px;
        color: white;
      }
    }

    &__close {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }
  }

  @media (min-width: 771px) and (max-width: 1045px) {
    top: 28px;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.2s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(2%);
    opacity: 1;
  }
}
</style>
