<template>
  <div class="merchantForm-section">
    <BreadCrumb
      class="top-left"
    />
    <!-- Banner Header -->
    <div
      v-if="checkMobile"
      class="merchantForm-section__banner"
    >
      <img
        :src="'/images/merchant/form_merchant_banner_mobile.svg'"
        alt="merchantForm"
      >
    </div>
    <!-- Banner Header -->
    <!-- Form -->
    <div class="merchantForm-section__form">
      <div class="merchantForm-section__form__coat">
        <h1 class="merchantForm-section__form__coat__title">
          Data Diri Pendaftar
        </h1>
        <div class="merchantForm-section__form__coat__wrapper">
          <Input
            v-model="form.pic"
            name="pic"
            placeholder="Masukkan nama PIC"
            :is-error="picError"
            :error-message="picErrorMsg"
            label="Nama PIC (Person in Charge)"
            :is-required="true"
            @input="handleInput('pic')"
          />
        </div>
        <div class="merchantForm-section__form__coat__wrapper">
          <Input
            v-model="form.phonenumber"
            name="phonenumber"
            prefix="+62"
            :placeholder="placeholderPhone"
            :is-error="phonenumberError"
            :error-message="phonenumberErrorMsg"
            label="No. Handphone"
            note="Pastikan nomor handphone aktif dan tersambung ke WA"
            :is-required="true"
            @input="handleInput('phonenumber')"
          />
        </div>
        <div class="merchantForm-section__form__coat__wrapper">
          <Input
            v-model="form.email"
            name="email"
            placeholder="Masukkan email anda"
            :is-error="emailError"
            :error-message="emailErrorMsg"
            label="Email"
            :is-required="true"
            @input="handleInput('email')"
          />
        </div>
        <div class="merchantForm-section__form__coat__wrapper">
          <Dropdown
            v-model="form.information"
            :options="CONSTANTS.SOURCE"
            :value="form.information"
            name="information"
            placeholder="Pilih Sumber Informasi"
            :is-error="informationError"
            :error-message="informationErrorMsg"
            label="Darimana Anda Mendapatkan Informasi Tentang BisaBelanja?"
            :is-required="true"
            :is-search="true"
            @input="handleInput('information')"
          />
        </div>
        <h1 class="merchantForm-section__form__coat__title2">
          Data Usaha
        </h1>
        <div class="merchantForm-section__form__coat__wrapper">
          <Input
            v-model="form.merchantName"
            name="merchantName"
            placeholder="Masukkan nama usaha"
            :is-error="merchantNameError"
            :error-message="merchantNameErrorMsg"
            label="Nama Usaha"
            :is-required="true"
            @input="handleInput('merchantName')"
          />
        </div>
        <div class="merchantForm-section__form__coat__wrapper">
          <Input
            v-model="form.productType"
            name="productType"
            placeholder="Masukkan jenis produk"
            :is-error="productTypeError"
            :error-message="productTypeErrorMsg"
            label="Jenis Produk"
            :is-required="true"
            @input="handleInput('productType')"
          />
        </div>
        <div class="merchantForm-section__form__coat__wrapper">
          <Input
            v-model="form.instagram"
            name="instagram"
            placeholder="Masukkan nama akun Instagram"
            label="Instagram Usaha"
            :is-required="false"
            :is-optional="true"
            @input="handleInput('instagram')"
          />
        </div>
        <div class="merchantForm-section__form__coat__wrapper">
          <Input
            v-model="form.merchantAddress"
            name="merchantAddress"
            placeholder="Masukkan alamat tempat usaha"
            :is-error="merchantAddressError"
            :error-message="merchantAddressErrorMsg"
            label="Alamat Tempat Usaha"
            :is-required="true"
            @input="handleInput('merchantAddress')"
          />
        </div>
        <div class="merchantForm-section__form__coat__wrapper">
          <Input
            v-model="form.maxPurchase"
            type="number"
            name="maxPurchase"
            :placeholder="placeholderMaxPurchase"
            label="Jumlah Maksimal Produk per Pembelian"
            :is-required="false"
            @input="validateMaxPurchase"
          />
        </div>
        <div class="merchantForm-section__form__coat__wrapper">
          <Radio
            v-model="form.packaging"
            :options="[{ value:'yes', label: 'Ya' }, { value: 'no', label: 'Tidak' }]"
            name="radio-packaging"
            :is-required="true"
            :is-error="packagingError"
            :error-message="packagingErrorMsg"
            label="Apakah Anda Dapat Menyiapkan Packaging yang Aman Untuk Pengiriman ke Luar Kota?"
            @input="handleInput('packaging')"
          />
        </div>
        <div class="merchantForm-section__form__coat__wrapper">
          <Radio
            v-model="form.platform"
            :options="[{ value:'yes', label: 'Ya' }, { value: 'no', label: 'Tidak' }]"
            name="radio-platform"
            :is-required="false"
            label="Apakah Anda Bersedia mengelola Toko Anda melalui platform khusus Official Merchant yang disediakan Lion Parcel?"
          />
        </div>
        <div class="merchantForm-section__form__coat__wrapper">
          <div class="merchantForm-section__form__coat__wrapper__cover">
            <Button
              text="Kirim"
              class="merchantForm-section__form__coat__wrapper__cover__button"
              @click="submit"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Form -->
    <!-- Modal -->
    <Modal :show-modal="showModal" />
    <!-- Modal -->
  </div>
</template>

<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import mixinMobile from '@/misc/mixinMobile';
import mixinMetaInfo from '@/misc/mixinMetaInfo';
import Input from '../components/Input.vue';
import Dropdown from '../components/Dropdown.vue';
import Radio from '../components/Radio.vue';
import Modal from '../components/Modal.vue';
import Button from '@/components/new-button/Button.vue';
import CONSTANTS from '../app/constants/informationSource';
import usecase from '../app/usecase/formMerchant';

export default {
  name: 'MerchantForm',
  components: {
    BreadCrumb, Input, Dropdown, Radio, Button, Modal,
  },
  mixins: [mixinMetaInfo, mixinMobile],
  data() {
    return {
      searchMetaInfo: '_merchantForm', // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      CONSTANTS,
      showModal: false,
      form: {
        pic: '',
        phonenumber: '',
        email: '',
        information: [],
        informationVal: '',
        merchantName: '',
        productType: '',
        instagram: '',
        merchantAddress: '',
        maxPurchase: '',
        packaging: '',
        platform: '',
      },
      picError: false,
      picErrorMsg: '',
      phonenumberError: false,
      phonenumberErrorMsg: '',
      emailError: false,
      emailErrorMsg: '',
      informationError: false,
      informationErrorMsg: '',
      merchantNameError: false,
      merchantNameErrorMsg: '',
      productTypeError: false,
      productTypeErrorMsg: '',
      merchantAddressError: false,
      merchantAddressErrorMsg: '',
      packagingError: false,
      packagingErrorMsg: '',
    };
  },
  computed: {
    placeholderPhone() {
      return this.windowWidth <= 599 ? 'diawali angka 8' : 'Masukkan nomor handphone dan diawali angka 8';
    },
    placeholderMaxPurchase() {
      return this.windowWidth <= 599 ? 'Masukkan jumlah produk' : 'Masukkan jumlah produk per pembelian';
    },
    checkMobile() {
      return this.windowWidth <= 599;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    handleInput(name) {
      switch (name) {
        case 'pic':
          this.picError = false;
          break;
        case 'phonenumber':
          this.phonenumberError = false;
          break;
        case 'email':
          this.emailError = false;
          break;
        case 'information':
          this.informationError = false;
          break;
        case 'merchantName':
          this.merchantNameError = false;
          break;
        case 'productType':
          this.productTypeError = false;
          break;
        case 'merchantAddress':
          this.merchantAddressError = false;
          break;
        case 'packaging':
          this.packagingError = false;
          break;
        default: break;
      }
    },
    validateMaxPurchase() {
      const regex = /\D+/g;
      this.form.maxPurchase = this.form.maxPurchase.replace(regex, '');
    },
    _elsePhoneNumber() {
      if (this.form.phonenumber.charAt(0) !== '8') {
        this.phonenumberError = true;
        this.phonenumberErrorMsg = 'Format yang anda masukan salah';
      } else if (this.form.phonenumber.length < 9 || this.form.phonenumber.length > 12) {
        // inc prefix +62 (total min 12 char - max 15 char)
        this.phonenumberError = true;
        this.phonenumberErrorMsg = 'Panjang karakter antara 9-12 angka';
      }
    },
    _elseEmail() {
      if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)) {
        this.emailError = false;
      } else {
        this.emailError = true;
        this.emailErrorMsg = 'Format yang anda masukan salah';
      }
    },
    check() {
      // pic
      if (!this.form.pic) {
        this.picError = true;
        this.picErrorMsg = 'Nama PIC wajib diisi';
      }
      // phonenumber
      if (!this.form.phonenumber) {
        this.phonenumberError = true;
        this.phonenumberErrorMsg = 'No. Handphone wajib diisi';
      } else {
        this._elsePhoneNumber();
      }
      // email
      if (!this.form.email) {
        this.emailError = true;
        this.emailErrorMsg = 'Email wajib diisi';
      } else {
        this._elseEmail();
      }
      // information
      try {
        this.form.informationVal = this.form.information[0].value;
      } catch (err) {
        this.form.informationVal = '';
      }
      if (!this.form.informationVal) {
        this.informationError = true;
        this.informationErrorMsg = 'Sumber informasi wajib diisi';
      }
      // merchantName
      if (!this.form.merchantName) {
        this.merchantNameError = true;
        this.merchantNameErrorMsg = 'Nama usaha wajib diisi';
      }
      // productType
      if (!this.form.productType) {
        this.productTypeError = true;
        this.productTypeErrorMsg = 'Jenis produk wajib diisi';
      }
      // merchantAddress
      if (!this.form.merchantAddress) {
        this.merchantAddressError = true;
        this.merchantAddressErrorMsg = 'Alamat tempat usaha wajib diisi';
      }
      // packaging
      if (!this.form.packaging) {
        this.packagingError = true;
        this.packagingErrorMsg = 'Kesiapan packaging wajib diisi';
      }

      return !this.picError
        && !this.phonenumberError
        && !this.emailError
        && !this.informationError
        && !this.merchantNameError
        && !this.productTypeError
        && !this.merchantAddressError
        && !this.packagingError;
    },
    async submit() {
      if (this.check()) {
        const form = {
          pic: this.form.pic,
          merchant_phone_number: `62${this.form.phonenumber}`,
          email: this.form.email,
          source_info: this.form.information[0].value,
          merchant_name: this.form.merchantName,
          product_type: this.form.productType,
          merchant_instagram: this.form.instagram,
          merchant_address: this.form.merchantAddress,
          max_purchase: parseInt(this.form.maxPurchase),
          is_packaging: this.form.packaging.target.value === 'yes',
          is_platform: this.form.platform.target.value === 'yes',
        };

        const res = await usecase.create(form);
        if (!res.err) {
          // clear field
          this.form = '';
          this.showModal = true;
        } else {
          alert(res.err.response.data.message.id);
        }
      }
    },
  },
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
