/** @type {Array<Content>} */
const SOURCE = [
  {
    label: 'Website Lion Parcel',
    value: 'Website Lion Parcel',
  },
  {
    label: 'Social Media Lion Parcel',
    value: 'Social Media Lion Parcel',
  },
  {
    label: 'Referral',
    value: 'Referral',
  },
  {
    label: 'Lainnya',
    value: 'Lainnya',
  },
];

module.exports = { SOURCE };
