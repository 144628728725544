<template>
  <div
    class="product-card"
    @click="goToProductDetail"
  >
    <div 
      class="product-card__image" 
      :class="isNotActive && 'disabled'"
    >
      <ImageLazy
        :src="product.image_url"
        alt="product list lion parcel"
        @error="replaceDefaultImage"
      />
    </div>
    <div class="product-card__info">
      <span class="product-card__info__name">
        {{ product.product_name }}
      </span>
      <span
        class="product-card__info__weight"
      >
        Berat: {{ product.weight }}gr
      </span>
      <span 
        class="product-card__info__price"
        :class="isNotActive && 'disabled'"
      >
        {{
          isNotActive ? 'Produk Habis' :
          'Rp' + convertRp(product.price)
        }}
      </span>
    </div>
  </div>
</template>
<script>
import { convertRp } from '@/misc/currency';
import ImageLazy from '@/components/ImageLazy/index.vue'

export default {
  name: 'ProductCard',
  components: { ImageLazy },
  props: {
    product: {
      type: Object,
      required: true
    },
    isNotActive: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      convertRp,
    }
  },
  methods: {
    goToProductDetail() {
      let merchant = this.product.product_name.split(' ').join('-').toLowerCase()
      let slug = encodeURIComponent(`${merchant}-${this.product.product_id}`)
      this.$router.push(`${this.$route.path}/${slug}`)
    },
    replaceDefaultImage(e) {
      e.target.src = '/images/merchant/default-product-image.svg' 
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/style.scss';
@mixin CustomFontSize($font-size: 34px, $line-height: 51px) {
  font-size: $font-size;
  line-height: $line-height;
}
.product-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid $color-hover-gray;
    border-radius: 16px 16px 0px 0px;

    > div {
      height: 148px;

      ::v-deep img {
        width: 148px;
        height: 148px;
        border-radius: 16px 16px 0px 0px;
        object-fit: cover;
      }
    }
  }
  &__image.disabled {
    opacity: 0.5;
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $color-gray-pensive;
    border: 0.5px solid $color-hover-gray;
    border-radius: 0px 0px 16px 16px;
    width: 148px;
    height: 94px;
    padding: 4px 10px 12px 12px;
    span {
      font-style: normal
    }
    &__name {
      @include CustomFontSize(12px, 18px);
      font-weight: 600;
      text-align: left;
      width: 124px;
      height: 37px;
      color: $color-indigo-dark2;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
              line-clamp: 1; 
      -webkit-box-orient: vertical;
    }
    &__weight {
      margin: 8px 4px 0px 0px;
      color: $color-gray-shade;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include CustomFontSize(10px, 15px);
    }
    &__price {
      @include CustomFontSize(14px, 21px);
      font-weight: 600;
      color: $color-indigo-dark2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__price.disabled {
      color: $color-gray-shade;
    }
  }
}
@media screen and (min-width: 1711px) {
  .product-card {
    &__image {
      border: 0.75px solid $color-hover-gray;
      border-radius: 24px 24px 0px 0px; 
      width: 222px;

      > div {
        height: 222px;

        ::v-deep img {
          width: 222px;
          height: 222px;
          border-radius: 24px 24px 0px 0px;
        }
      }
    }
    &__info {
      border: 0.75px solid $color-hover-gray;
      border-radius: 0px 0px 24px 24px;
      width: 222px;
      height: 141px;
      padding: 6px 15px 18px 18px;
      &__name {
        @include CustomFontSize(18px, 27px);
        width: 186px;
        height: 56px;
      }
      &__weight {
        margin: 12px 6px 0px 0px;
        @include CustomFontSize(15px, 22.5px);
      }
      &__price {
        @include CustomFontSize(21px, 31.5px);
      }
    }
  }
}
@media screen and (min-width: 1602px) and (max-width: 1710px) {
  .product-card {
    &__image {
      border: 0.7px solid $color-hover-gray;
      border-radius: 22.4px 22.4px 0px 0px; 
      
      > div {
        height: 207.2px;

        ::v-deep img {
          width: 207.2px;
          height: 207.2px;
          border-radius: 22.4px 22.4px 0px 0px;
        }
      }
    }
    &__info {
      border: 0.7px solid $color-hover-gray;
      border-radius: 0px 0px 22.4px 22.4px;
      width: 207.2px;
      height: 131.6px;
      padding: 5.6px 14px 16.8px 16.8px;
      &__name {
        @include CustomFontSize(16.8px, 25.2px);
        width: 173.6px;
        height: 44.8px;
      }
      &__weight {
        margin: 12px 6px 0px 0px;
        @include CustomFontSize(14px, 21px);
      }
      &__price {
        @include CustomFontSize(19.6px, 29.4px);
      }
    }
  }
}
@media screen and (min-width: 1502px) and (max-width: 1601px) {
  .product-card {
    &__image {
      border: 0.65px solid $color-hover-gray;
      border-radius: 20.8px 20.8px 0px 0px; 
      
      > div {
        height: 192.4px;

        ::v-deep img {
          width: 192.4px;
          height: 192.4px;
          border-radius: 20.8px 20.8px 0px 0px;
        }
      }
    }
    &__info {
      border: 0.65px solid $color-hover-gray;
      border-radius: 0px 0px 20.8px 20.8px;
      width: 192.4px;
      height: 122.2px;
      padding: 5.2px 13px 15.6px 15.6px;
      &__name {
        @include CustomFontSize(15.6px, 23.4px);
        width: 161.2px;
        height: 45.6px;
      }
      &__weight {
        margin: 10.4px 5.2px 0px 0px;
        @include CustomFontSize(13px, 19.5px);
      }
      &__price {
        @include CustomFontSize(18.2px, 27.3px);
      }
    }
  }
}
@media screen and (min-width: 1402px) and (max-width: 1501px) {
  .product-card {
    &__image {
      border: 0.55px solid $color-hover-gray;
      border-radius: 17.6px 17.6px 0px 0px; 
      width: 168.2px;
      
      > div {
        height: 168.2px;

        ::v-deep img {
          width: 168.2px;
          height: 168.2px;
          border-radius: 17.6px 17.6px 0px 0px;
        }
      }
    }
    &__info {
      border: 0.55px solid $color-hover-gray;
      border-radius: 0px 0px 17.6px 17.6px;
      width: 168.2px;
      height: 103.4px;
      padding: 4.4px 11px 13.2px 13.2px;
      &__name {
        @include CustomFontSize(13.2px, 19.8px);
        width: 136.4px;
        height: 40.2px;
        
      }
      &__weight {
        margin: 8.8px 4.4px 0px 0px;
        @include CustomFontSize(11px, 16.5px);
      }
      &__price {
        @include CustomFontSize(15.4px, 23.1px);
      }
    }
  }
}
@media screen and (min-width: 0px) and (max-width: 359px) {
  .product-card {
    &__image {
      width: 130px;
    }
    &__info {
      width: 130px;
      &__name {
        width: unset;
      }
    }
  }
}
</style>
